import React from "react";
import { Link } from "gatsby";

import { LayoutMain } from "../layouts/layout-main/layout-main";

const Tags = () => (
  <LayoutMain isBlogPost={false} title="Tags">
    <article className="page-content">
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <Link to="/">Go back to the homepage</Link>
    </article>
  </LayoutMain>
);

export default Tags;
