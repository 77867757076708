import React from "react";

import { HeaderMain, FooterMain, SeoHeaders } from "../../components";
import { wrapper, mainContent } from "./layout-main.module.scss";

export function LayoutMain({
  children,
  isBlogPost = false,
  title,
  metaTitle,
  frontmatter = {},
}: {
  children: React.ReactNode;
  isBlogPost: boolean;
  title: string;
  metaTitle?: string;
  frontmatter?: any;
}) {
  return (
    <>
      <SeoHeaders
        frontmatter={frontmatter}
        isBlogPost={isBlogPost}
        title={metaTitle || title}
      />
      <div className={wrapper}>
        <HeaderMain />
        <div className={mainContent}>{children}</div>
        <FooterMain />
      </div>
    </>
  );
}

export function LayoutWithSiteData(props: any) {
  return <LayoutMain {...props} />;
}
